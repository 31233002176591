import React from 'react'

const Login = ({ authenticate }) => {
  return (
    <div className='login'>
      <h2>Les recettes se chargent Attendez !!!!</h2>
      <h2>Si tu n'est pas connecté clique ci-dessous !</h2>
      <button onClick={authenticate} className='facebook-button'>
        Me connecter avec Facebook
      </button>
    </div>
  )
}

export default Login
