import React from 'react'

const withPlaceholder = WrappedComponent => (props) => (
  <WrappedComponent
    placeholder='Mon Pseudo'
    {...props}
  />
)

export default withPlaceholder
